







import {Component, Vue} from "vue-property-decorator";
import ReceiptList from "@/views/cash-book/receipt/receipt-list.vue";
import {ReceiptType} from "@/constants/receipt";

@Component({
    name: 'receiverVoucherList',
    components: {
        ReceiptList: ReceiptList
    }
})

export default class ReceiverVoucherList extends Vue {
    receiptType = ReceiptType;
}
